<template>
  <div class="container-fluid">
    <div class="row min-vh-100 flex-center no-gutters">
      <div class="col-lg-8 col-xxl-5 py-3">
        <img class="bg-auth-circle-shape" src="@/assets/img/illustrations/bg-shape.png" alt="" width="250">
        <img class="bg-auth-circle-shape-2" src="@/assets/img/illustrations/shape-1.png" alt="" width="150">
        <div class="card overflow-hidden z-index-1">
          <div class="card-body p-0">
            <div class="row no-gutters h-100">
              <div class="col-md-5 order-sm-1 order-2 text-white text-center bg-card-gradient">
                <div class="position-relative p-4 pt-md-5 pb-md-7">
                  <div 
                    class="bg-holder bg-auth-card-shape" 
                    style="background-image:url(/img/illustrations/half-circle.png);">
                  </div>
                  <!--/.bg-holder-->

                  <div class="z-index-1 position-relative">
                    <router-link class="navbar-brand fs-5 mt-2" to="/" exact>
                      <div class="d-flex align-items-center text-primary ">
                        <img 
                        class="mr-1" 
                        src="@/assets/img/illustrations/bplan-logo.png" style="filter: contrast(150%);" alt="" width="45" />
                        <span class="text-white font-proxima">ТВОЙ<small>план</small> </span>
                      </div>
                    </router-link>
                    <p class="text-100">
                      Надежный и практичный инструмент помогающий обрести 
                      финансовую <strong>стабильность</strong> и <strong>независимость</strong>!
                    </p>
                  </div>
                </div>
                <div class="mt-2 mb-4 mt-md-4 mb-md-5">
                  <p>Еще не зарегистрированы?<br>
                    <router-link 
                      class="btn btn-outline-light btn-sm mt-3 px-4" 
                      to="/registration">
                      Присоединяйтесь!
                    </router-link>
                  </p>
                  <p class="mb-0 mt-5 mt-md-5 fs--2 font-weight-semi-bold text-300">
                    Прочитать 
                    <a class="text-underline text-300" href="#!">условия</a> 
                    и <a class="text-underline text-300" href="#!">политику конфеденциальности</a>
                  </p>
                </div>
              </div>
              <div class="col-md-7 order-sm-2 order-1 d-flex flex-center">
                <div class="p-4 p-md-5 flex-grow-1 mt-2">
                  <h3>Вход в систему</h3>
                  <form>
                    <div class="form-group mt-3">
                      <label for="card-email">Логин</label>
                      <input 
                        class="form-control" 
                        type="email" 
                        placeholder="Ваш email адрес"
                        v-model.trim="$v.email.$model"
                        :disabled="loading"
                        :class="{
                          'is-invalid': $v.email.$error
                        }"
                      />
                    </div>
                    <div class="form-group">
                      <div class="d-flex justify-content-between">
                        <label for="card-password">Пароль</label>
                        <router-link class="fs--1" to="/login?password=recovery">
                          Забыли пароль?
                        </router-link>
                      </div>
                      <input 
                        class="form-control" 
                        type="password" 
                        placeholder="Введите пароль"
                        v-model.trim="$v.password.$model"
                        :disabled="loading" 
                        :class="{
                          'is-invalid': $v.password.$error,
                        }"  
                      />
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input class="custom-control-input" type="checkbox" id="card-checkbox" />
                      <label class="custom-control-label" for="card-checkbox">Запомнить меня</label>
                    </div>
                    <div class="form-group">
                      <button 
                        class="btn btn-primary btn-block mt-4" 
                        type="submit" 
                        name="submit"
                        @click.prevent="getLogin">
                        <span 
                          v-if="loading"
                          class="spinner-border spinner-border-sm" 
                          role="status" 
                          aria-hidden="true">
                        </span>
                        Войти
                      </button>
                    </div>
                  </form>
                  <div class="w-100 position-relative mt-5">
                    <hr class="text-300" />
                    <div class="position-absolute absolute-centered t-0 px-3 bg-white text-sans-serif fs--1 text-500 text-nowrap">
                      или войти при помощи
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="row no-gutters">
                      <div class="col-sm-6 pr-sm-1">
                        <a class="btn btn-outline-google-plus btn-sm btn-block mt-2 disabled" href="#">
                          <span class="fab fa-google-plus-g mr-2 fa-lg" data-fa-transform="grow-8"></span> 
                          google
                        </a>
                      </div>
                      <div class="col-sm-6 pl-sm-1">
                        <a class="btn btn-outline-facebook btn-sm btn-block mt-2 disabled" href="#">
                          <span class="fab fa-facebook fa-lg mr-2" data-fa-transform="grow-8"></span> 
                          facebook
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { email, required, minLength } from 'vuelidate/lib/validators'
import messages  from '@/utils/messages'

export default {
  metaInfo() {
    return {
      title: 'Вход'
    }
  },
  data: () => ({
    email: '',
    password: '',
    rememberCheckBox: false,
    toastCount: 0,
    loading: false
  }),
  mounted() {
    this.loading = false
    if (messages[this.$route.query.message]) {
      this.$message(messages[this.$route.query.message])
    } 
  },
  validations: {
    email: {email, required},
    password: {required, minLength: minLength(6)},
  },
  methods: {
    async getLogin() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      
      const loginData = {
      email: this.email,
      password: this.password
      }
      
      try {
        this.loading = true
        await this.$store.dispatch('login', loginData)
        this.$router.push('/bplan')
      } catch (error) {
        this.loading = false
      }

    }
  }
}
</script>
